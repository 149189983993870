import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { api, refreshToken, getUserInfo } from '../api/api';
import { useHistory } from 'react-router-dom';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const history = useHistory();
  const [shouldShowReminderModal, setShouldShowReminderModal] = useState(false);

  let isRefreshing = false;
  let refreshSubscribers = [];

  function subscribeRefreshToken(callback) {
    refreshSubscribers.push(callback);
  }

  function onRefreshed(accessToken) {
    refreshSubscribers.forEach(callback => callback(accessToken));
    refreshSubscribers = [];
  }

  const apiUrl = process.env.REACT_APP_API_URL;

  const refreshAccessToken = async () => {
    if (!isRefreshing) {
      isRefreshing = true;
      try {
        const response = await refreshToken();
        isRefreshing = false;
        const newAccessToken = response.newAccessToken;
        onRefreshed(newAccessToken);
        return newAccessToken;
      } catch (error) {
        isRefreshing = false;
        console.error("Échec du rafraîchissement du token d'accès:", error);
        return false;
      }
    } else {
      return new Promise((resolve) => {
        subscribeRefreshToken((accessToken) => {
          resolve(accessToken);
        });
      });
    }
  };

  api.interceptors.response.use(async (response) => {
    return response;
  }, async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newAccessToken = await refreshAccessToken();
      if (newAccessToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`; // Mettre à jour l'en-tête par défaut pour toutes les requêtes
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`; // Mettre à jour l'en-tête pour la requête actuelle
        return api(originalRequest); // Réessayer la requête avec le nouveau token
      } else {
        // Gestion de l'échec du rafraîchissement du token
        setIsLoggedIn(false);
        setUser(null);
        history.push('/login');
      }
    }
    return Promise.reject(error);
  });

  const logIn = async (email, password) => {
    try {
      const response = await axios.post(
        `${apiUrl}/auth/login`,
        { email, password },
        { withCredentials: true }
      );
      if (response.status === 200) {
        setIsLoggedIn(true);
        setUser(response.data.user);
        return response.data;  // Retournez les données ici
      }
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
      alert("Login error.");
      return false;
    }
  };

  const logInWithOAuth = useCallback(async (userData) => {
    setIsLoggedIn(true);
    setUser(userData);

    try {
        const fullUserData = await getUserInfo(); // Assurez-vous que getUserInfo est correctement implémentée pour gérer les appels asynchrones
        if (fullUserData.isOnBoarded !== undefined && !fullUserData.isOnBoarded) {
            history.push('/stripe-account-connect');
        } else {
            history.push('/mon-compte');
        }
    } catch (error) {
        console.error("Erreur lors de la récupération des données de l'utilisateur après OAuth:", error);
        // Gérer l'erreur éventuellement en redirigeant l'utilisateur vers une page d'erreur ou en affichant un message
    }
    
}, [setIsLoggedIn, setUser, history]);

  const logOut = async () => {
    try {
      const response = await axios.post(`${apiUrl}/auth/logout`, {}, { withCredentials: true });
      if (response.status === 200) {
        setIsLoggedIn(false);
        setUser(null);
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}/auth/check-login`,
          {},
          { withCredentials: true }
        );

        if (response.status === 200) {
          setIsLoggedIn(true);
          // et autres logiques d'initialisation...
        }
      } catch (error) {
        setIsLoggedIn(false);
        // et autres logiques de nettoyage...
      }
      setIsLoading(false);  // Importante! Cette ligne indique que le chargement est terminé
    };

    checkLoginStatus();
  }, []);

  const authContextValue = {
    isLoggedIn,
    logIn,
    logInWithOAuth,
    logOut,
    user,
    isLoading,
    shouldShowReminderModal,
    setShouldShowReminderModal,
      // Ajoutez aussi cela pour pouvoir l'utiliser dans PrivateRoute
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};