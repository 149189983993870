import React, { useContext, useEffect } from 'react'; // useState
import { useHistory, Link } from 'react-router-dom';
// import zxcvbn from 'zxcvbn';
import { Form, Input, Button, Checkbox, Collapse, Card, notification } from 'antd';
import { UserOutlined, LoginOutlined } from '@ant-design/icons';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import getGoogleOAuthURL from '../utils/getGoogleUrl';
import { createButton } from 'react-social-login-buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';
import '../Register.css';

const { Panel } = Collapse;

const Register = () => {
  const history = useHistory();
  // const [passwordStrength, setPasswordStrength] = useState(null);
  const { logIn, logInWithOAuth, shouldShowReminderModal, setShouldShowReminderModal, setUser, setIsLoggedIn, } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    function handleLoginMessage(event) {
      if (event.origin === process.env.REACT_APP_API_URL && event.data && event.data.type === 'loginSuccess') {
        logInWithOAuth(event.data.userData);
        history.push('/login-register');
      } else {
        console.log("Unexpected message or origin:", event.data, event.origin);
      }
    }
    window.addEventListener('message', handleLoginMessage);

    return () => {
      window.removeEventListener('message', handleLoginMessage);
    };
  }, [history, logInWithOAuth]);

  const openGoogleLogin = () => {
    const googleUrl = getGoogleOAuthURL();
    const width = 600, height = 600;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    const windowFeatures = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`;
    window.open(googleUrl, 'GoogleLogin', windowFeatures);
  };

  const onFinishRegister = (values) => {
    axios.post(`${apiUrl}/auth/register`, {
        email: values.registerEmail,
        password: values.registerPassword,
        firstName: values.registerFirstName,
        lastName: values.registerLastName,
    })
    .then((response) => {
        notification.success({
            message: 'Inscription réussie',
            description: 'Votre compte a été créé avec succès. Vous pouvez maintenant vous connecter.',
            duration: 4
        });
        history.push('/login-register');
    })
    .catch((error) => {
        if (error.response && error.response.data.message === "Un utilisateur avec cet email existe déjà.") {
            notification.error({
                message: 'Échec de l’inscription',
                description: 'Un utilisateur avec cet e-mail existe déjà.',
                duration: 4
            });
        } else {
            notification.error({
                message: 'Échec de l’inscription',
                description: 'Une erreur est survenue lors de l\'inscription. Veuillez réessayer plus tard.',
                duration: 4
            });
        }
    });
};

  // const onPasswordChange = (e) => {
  //   const password = e.target.value;
  //   const evaluation = zxcvbn(password);
  //   setPasswordStrength(evaluation.score);
  // };

  // const getPasswordStrengthIndicator = () => {
  //   switch (passwordStrength) {
  //     case 0:
  //       return 'Très faible';
  //     case 1:
  //       return 'Faible';
  //     case 2:
  //       return 'Moyen';
  //     case 3:
  //       return 'Bon';
  //     case 4:
  //       return 'Fort';
  //     default:
  //       return '';
  //   }
  // };

  const onFinishLogin = async (values) => {
    const response = await logIn(values.loginEmail, values.loginPassword);

    if (response && response.openProjectsCount !== undefined) {
      if (!response.user.isOnBoarded) {
        history.push('/stripe-account-connect');
        return;
      }
      const preLogoutRoute = localStorage.getItem('preLogoutRoute');
      if (response.openProjectsCount > 0) {
        setShouldShowReminderModal(true);
      }
      if (preLogoutRoute) {
        history.push(preLogoutRoute);
        localStorage.removeItem('preLogoutRoute');
      } else {
        history.push('/mon-compte');
      }
    } else {
      console.log("Error while logging in");
    }
  };

  const Icon = () => <FontAwesomeIcon icon={faGoogle} />;

  const config = {
    text: "Se connecter avec Google",
    icon: Icon,
    style: { background: "#071015", borderRadius: "15px" },
    activeStyle: { background: "#ff0000" },
  };

  const MyGoogleLoginButton = createButton(config);

  return (
    <div className="container-form">
      <Helmet>
        <title>Se Connecter - Your Guests</title>
        <meta name="description" content="Se connecter ou créer un compte pour organiser les réservation sur " />
        <meta name="keywords" content="invités,your guests,mariage,your-guests,guests,réservation,réserver,lieu,dormir" /> 
      </Helmet>
      <div className="form-container">
        <Card style={{ borderRadius: '15px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
          <Collapse className='cust-collaps-login' accordion expandIconPosition="right" bordered={false}>
            <Panel header={
              <>
                <LoginOutlined style={{ position: 'relative', top: '-4px', fontSize: '20px', marginRight: '15px' }} />
                <span style={{ fontSize: '20px', fontFamily: 'Roboto' }}> Se connecter  </span>
              </>
            } key="login">
              <Form onFinish={onFinishLogin} className='custom-form-login'>
                <Form.Item
                  name="loginEmail"
                  rules={[{ required: true, message: "Veuillez saisir votre email!" }]}
                  colon={false}
                  label={<span> </span>}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  name="loginPassword"
                  rules={[{ required: true, message: "Veuillez saisir votre mot de passe!" }]}
                  colon={false}
                  label={<span> </span>}
                >
                  <Input.Password placeholder="Mot de passe" />
                </Form.Item>

                <Form.Item
                  name="remember"
                  valuePropName="checked"
                >
                  <Checkbox>Se souvenir de moi</Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className='register-button'
                  >
                    Se connecter
                  </Button>
                  <a href="/forgot-password" style={{ float: 'right' }}>Mot de passe oublié ?</a>
                </Form.Item>
              </Form>
            </Panel>
            <Panel header={
              <>
                <UserOutlined style={{ position: 'relative', top: '-4px', fontSize: '20px', marginRight: '15px' }} />
                <span style={{ fontSize: '20px', fontFamily: 'Roboto' }}> S'inscrire</span>
              </>
            } key="register">
              <Form onFinish={onFinishRegister}>
                <Form.Item
                  name="registerLastName"
                  rules={[{ required: true, message: "Veuillez saisir votre nom!" }]}
                  colon={false}
                  label={<span> </span>}
                >
                  <Input placeholder="Nom" />
                </Form.Item>
                <Form.Item
                  name="registerFirstName"
                  rules={[{ required: true, message: "Veuillez saisir votre prénom!" }]}
                  colon={false}
                  label={<span> </span>}
                >
                  <Input placeholder="Prénom" />
                </Form.Item>
                <Form.Item
                  name="registerEmail"
                  rules={[
                    { required: true, message: "Veuillez saisir votre email!" },
                    { type: "email", message: "Veuillez saisir un email valide!" }
                  ]}
                  colon={false}
                  label={<span> </span>}
                >
                  <Input placeholder="Email" type="email" />
                </Form.Item>
                <Form.Item
                  name="registerPassword"
                  rules={[{ required: true, message: "Veuillez saisir votre mot de passe!" }]}
                  colon={false}
                  label={<span> </span>}
                >
                  <Input.Password placeholder="Mot de passe" />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  dependencies={['registerPassword']}
                  rules={[
                    { required: true, message: "Veuillez confirmer votre mot de passe!" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('registerPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Les mots de passe ne correspondent pas!"));
                      },
                    })
                  ]}

                  colon={false}
                  label={<span> </span>}
                >
                  <Input.Password placeholder="Confirmer votre mot de passe" />
                </Form.Item>
                <Form.Item
                    name="terms"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject(new Error('Vous devez accepter les conditions d’utilisation et la politique de confidentialité.')),
                      },
                    ]}
                    colon={false}
                  >
                    <Checkbox>
                      J'accepte les <Link to="/terms">Conditions d'utilisation</Link> et la <Link to="/privacy">Politique de confidentialité</Link>.
                    </Checkbox>
                  </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className='register-button'
                >
                  S'inscrire
                </Button>
              </Form>
            </Panel>
          </Collapse>
        </Card>
        <div className="google-login">
          <MyGoogleLoginButton onClick={openGoogleLogin} />
        </div>
      </div>
    </div>
  );
};

export default Register;