import { useEffect } from 'react';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import weddingIcon from '../assets/icons/Marker_Events.png';
import hotelIcon from '../assets/icons/hotel_icon_marker.png'
import sanClient from '../sanityClient';
import { urlFor } from './imageUrlBuilder';
import '../LeafLetSearch.css'

const SearchField = ({ onLocationSelect, onLocationNotFound, openModal }) => {
  const map = useMap();
  const provider = new OpenStreetMapProvider();

  const weddingMarker = new L.Icon({
    iconUrl: weddingIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const hotelMarkerIcon = new L.Icon({
    iconUrl: hotelIcon, // Chemin vers votre icône d'hôtel
    iconSize: [32, 32], // Taille de l'icône
    iconAnchor: [16, 32], // Point de l'icône qui correspondra exactement aux coordonnées géographiques
    popupAnchor: [0, -32] // Point où la popup s'ouvrira
  });

  const YGpopup = new L.Popup({
    closeButton: true,
    autoClose: false,
  })

  useEffect(() => {
    const searchControl = new GeoSearchControl({
      notFoundMessage: "Désolé, ce lieu n'a pas été trouvé",
      provider: provider,
      style: 'bar',
      showMarker: true,
      showPopup: true,
      marker: {
        icon: weddingMarker,
        draggable: false,
      },
      maxMarker: 1,
      searchLabel: 'Rechercher le lieu',
    }).addTo(map);

    map.addControl(searchControl);

    function removeStopWords(keywords) {
      const stopWords = ['de', 'du', 'le', 'la', 'les', 'des'];
      return keywords.filter(word => !stopWords.includes(word.toLowerCase()));
    }

    function roundCoord(number) {
      return Math.round(number * 1e7) / 1e7;
    }

    function buildKeywordsQuery(keywords) {
      return keywords.map(keyword => `name match "*${keyword}*" `).join(' || ');
    }

    function truncateText(text, maxLength) {
      return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    }

    const handleShowLocation = async (result) => {

      if (result.marker) {
        // Traitez la localisation ici
        const latitude = roundCoord(parseFloat(result.location.y));
        const longitude = roundCoord(parseFloat(result.location.x));

        let labelParts = result.location.label.split(',').map(part => part.trim());
        let keywords = labelParts.length > 0 ? labelParts[0].split(' ') : [];
        keywords = removeStopWords(keywords);

        const MAX_KEYWORDS = 5;
        keywords = keywords.slice(0, MAX_KEYWORDS);

        const keywordsQuery = buildKeywordsQuery(keywords);

        let venue;

        const query = `*[_type == "weddingVenue" && latitude == $lat && longitude == $lng && (${keywordsQuery})]`;

        try {
          const sanityResponse = await sanClient.fetch(query, { lat: latitude, lng: longitude });

          if (sanityResponse.length > 0) {
            venue = sanityResponse[0];
            const truncatedDescription = truncateText(venue.description, 120);
            YGpopup.setContent(
              `
                <div>
                  <img src="${urlFor(venue.logo).auto('format').width(60).url()}" alt="${venue.name}" />
                  <h3 style="font-weight: bold;">${venue.name}</h3>
                  <p>${truncatedDescription}</p>
                  <button id="selectVenueButton">Sélectionner</button>
                </div>`
            );
            if (onLocationSelect) {
              onLocationSelect(venue);
            }

          } else {
            // Contenu par défaut de la popup
            YGpopup.setContent("<p>Ce lieu n'est pas encore proposé par Your-Guests. Vous pouvez demander de l'ajouter : <a href='/contact' class='contact-link'>Nous contacter</a>.</p>");

            if (onLocationNotFound) {
              onLocationNotFound(YGpopup);
            }
          }

          result.marker.bindPopup(YGpopup).openPopup();

          const handleSelectButtonClick = () => openModal();

          result.marker.on('popupopen', () => {
            const selectButton = document.getElementById('selectVenueButton');
            if (selectButton) {
              selectButton.addEventListener('click', handleSelectButtonClick);
            }
          });

          result.marker.on('popupclose', () => {
            const selectButton = document.getElementById('selectVenueButton');
            if (selectButton) {
              selectButton.removeEventListener('click', handleSelectButtonClick);
            }
            // Réinitialiser le comportement de clic du marqueur pour réouvrir la popup
            result.marker.off('click').on('click', () => {
              result.marker.openPopup();
            });
          });
        } catch (error) {
          console.error('Erreur lors de la requête Sanity:', error);
        }

        try {
          const overpassQuery = `
              [out:json];
              node
                [tourism=hotel]
                (around:30000,${latitude},${longitude});
              out;`;
          const overpassUrl = `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(overpassQuery)}`;

          const overpassResponse = await fetch(overpassUrl);
          const overpassData = await overpassResponse.json();

          const hotelsAround = overpassData.elements.map(hotel => {
            return {
              name: hotel.tags.name || 'Hôtel sans nom',
              address: hotel.tags.address || 'Adresse non disponible',
              latitude: hotel.lat,
              longitude: hotel.lon,
              website: hotel.tags.website || 'Site web non disponible',
              description: hotel.tags.description || 'Aucune description disponible'
            };
          });

          if (venue) {
            venue.hotelsAround = hotelsAround;
          }

          overpassData.elements.forEach(hotel => {
            const { lat, lon, tags } = hotel;
            const hotelName = tags.name || 'Hôtel sans nom';
            const hotelInfo = tags.description || 'Aucune description disponible';
            const HotelMarker = L.marker([lat, lon], { icon: hotelMarkerIcon }).addTo(map);

            // Création du marqueur Leaflet pour l'hôtel

            HotelMarker.bindPopup(`
                    <strong>${hotelName}</strong><br>
                    ${hotelInfo}
                  `);
          });

          let pointsOfInterest = [[latitude, longitude]];
          overpassData.elements.forEach(hotel => {
            pointsOfInterest.push([hotel.lat, hotel.lon]);
          });

          let bounds = L.latLngBounds(pointsOfInterest);
          map.fitBounds(bounds);

        } catch (error) {
          console.error('Erreur lors de la requête Overpass:', error);
        }
      }
    };

    map.on('geosearch/showlocation', handleShowLocation);

    return () => {
      map.removeControl(searchControl);
      map.off('geosearch/showlocation', handleShowLocation);
    };
  }, [map, onLocationSelect, onLocationNotFound]);

  return null;
};

export default SearchField;
