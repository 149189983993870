// ResetPasswordForm.js

import React, { useState } from 'react';
import axios from 'axios';

function ResetPasswordForm() {
    const [email, setEmail] = useState('');
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      try {
        const response = await axios.post('/api/auth/forgot-password', { email });
      } catch (error) {
        console.error(error);
      }
    };
  
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <h1>Hello, this is the reset password form!</h1>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <label>Email
              <input 
                type="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required />
            </label>
            <button type="submit">Reset Password</button>
          </form>
        </div>
    );
}

export default ResetPasswordForm;
