import React, { useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import SearchField from './LeafLetSearch';

function WeddingMapComp({ handleVenueSelect, handleVenueNotFound, openModal  }) {
  const [position, setPosition] = useState([48.866667, 2.333333]);
  const [showSearch, setShowSearch] = useState(true);
  const apiKey = process.env.REACT_APP_MAPBOX_API_KEY;


  return (
    <MapContainer
      center={position}
      zoom={15}
      style={{ height: "600px", width: "80%", margin: "0 auto" }}
    >
      {showSearch && <SearchField onLocationSelect={handleVenueSelect} onLocationNotFound={handleVenueNotFound} openModal ={openModal} />}
      <TileLayer
        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
        attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>'
        id="mapbox/streets-v11"
        accessToken={apiKey} // utilisez la même clé API que celle passée à SearchField
      />
    </MapContainer>
  );
}

export default WeddingMapComp;