import React, { useEffect } from 'react';

export default function FullPageScroll({ children }) {

  useEffect(() => {

    function fullPageScroll() {
      const sections = document.querySelectorAll("section");
      let currentSectionIndex = 0;
      let isThrottled = false;

      let lastVisibleSectionIndex = 0;

      function scrollHandler(e) {
        if (isThrottled) return;
        isThrottled = true;

        setTimeout(() => {
          isThrottled = false;
        }, 400);

        const currentVisibleSectionIndex = Array.from(sections).findIndex(section => {
          const rect = section.getBoundingClientRect();
          return rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2;
        });

        if (currentVisibleSectionIndex === -1) {
          currentSectionIndex = lastVisibleSectionIndex;
        } else {
          currentSectionIndex = currentVisibleSectionIndex;
          lastVisibleSectionIndex = currentVisibleSectionIndex;
        }

        if (e.deltaY > 0) {
          currentSectionIndex++;
        } else if (e.deltaY < 0) {
          currentSectionIndex--;
        }

        currentSectionIndex = Math.min(Math.max(currentSectionIndex, 0), sections.length - 1);

        if (sections[currentSectionIndex]) {
          sections[currentSectionIndex].scrollIntoView({ behavior: "smooth" });
        }
      }

      window.addEventListener("wheel", scrollHandler);

      // Cleanup listener on unmount
      return () => {
        window.removeEventListener("wheel", scrollHandler);
      }
    }

    window.onload = fullPageScroll;

  }, []);

  return (
    <div>
      {children}
    </div>
  );
}