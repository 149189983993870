import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-scroll';
import { BsArrowDownCircle } from 'react-icons/bs';
import Logo from '../assets/icons/Logo YG Seul Violet primaire contour.png'
import '../IntroSection.css';

const detectScale = () => {
  const scale = window.devicePixelRatio || 1;
  if (scale > 1) {
    document.body.classList.add('scaled');
  }
};

export default function IntroSection() {

  useEffect(() => {
    const parallax = (event) => {
      document.querySelectorAll(".layer").forEach(layer => {
        const speed = layer.getAttribute("data-speed");
        const x = (window.innerWidth - event.pageX * speed) / 100;
        const y = (window.innerHeight - event.pageY * speed) / 100;

        layer.style.transform = `translateX(${x}px) translateY(${y}px)`;
      });
    };

    document.addEventListener("mousemove", parallax);
    return () => document.removeEventListener("mousemove", parallax);
  }, []);
  const fadeInFromBottom = useSpring({
    to: { opacity: 1, marginTop: 0 },
    from: { opacity: 0, marginTop: 200 },
    delay: 500,
  });
  
  
  useEffect(() => {
    detectScale();
  }, []);


  return (
    <section id="intro" className='intro-section'> 
      <div className="parallax-container">
        <div className="layer background" data-speed="2"></div>
        <div className="layer overlay" data-speed="5"></div>
      </div>
      <animated.div style={fadeInFromBottom}>
        {/* Introductory content goes here */}
        <div className="yg-logo">
          <img src={Logo} alt="YG Logo" />
        </div>
        <div className="line-container">
          <h1 className='underline introH1'>
            Simplifiez les réservations de chambres<br/>
            pour votre mariage<br/>
            en un clin d'œil
          </h1>
          {/* <h1 className='introH1'>
            p
          </h1>
          <h1 className=' introH1'>
            en un clin d'œil
          </h1> */}
          <p className="intro-text">
          Bienvenue sur Your Guests, votre solution pour simplifier la réservation de chambres pour les invités de votre mariage. Découvrez nos services et commencez à planifier votre mariage sans stress.
          </p>
        </div>       
        <button>
          <Link to="nos-services" spy={true} smooth={true}>
            {({ isActive }) => (
              <a className={isActive ? 'active' : ''}>Nos Services</a>
            )}
          <BsArrowDownCircle className="icon" size="4rem"/>
          </Link>
        </button>
      </animated.div>
      
    </section>
  );
}