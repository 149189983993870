import React, { useEffect, useState } from "react";
import styles from '../ResaTopBanner.module.css'
import logoYG from '../assets/icons/LOGO.png';

const TopBanner = ({ children }) => {
    const [animate, setAnimate] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setAnimate(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div className={styles.headerSpacer}></div>
            <div className={styles.topBanner}>
                <img
                    src={logoYG}
                    alt="Logo Your guests"
                    className={`${styles.logo} ${animate ? styles.logoAnimate : ''}`}
                />
                <div className={`${styles.divider} ${animate ? styles.dividerAnimate : ''}`}></div>
                <h1 className={`${styles.reservationH1} ${animate ? styles.h1Animate : ''}`}>
                    {children}
                </h1>
            </div>
        </>
    );
};

export default TopBanner;