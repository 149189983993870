import React, { useRef, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useLocation } from 'react-router-dom';
import SuiteCard from '../components/SuiteCard';
import RoomCard from '../components/RoomCard';
import IconForNights from '../components/IconsForNights';
import {
  getGuestsInfoResa, getPublicSartEndDates, getPublicConfigs, getPublicDescAndHotels, getPublicReservedRoomId, fetchRoomAssignments,
  getExtraBedsPrice, getTotalBeds, getPublicVenueID, savePublicReservation, getPublicOfferBool, deletePublicExistingReservation,
  createCheckoutSession, getPublicUserInfo, confirmPublicReservation, checkRoomAvailability, sendPublicPaymentConfirmationEmail
} from '../api/api';
import { loadStripe } from '@stripe/stripe-js';
import { fetchSanityVenueInfo, fetchVenueEquipments } from '../components/ReservationHelpers';
import ReservationModal from '../components/ReservationModal';
import TopBanner from '../components/ResaTopBanner';
import VenueDetails from '../components/VenueEquip';
import CountdownTimer from '../components/CountDown';
import Gallery from '../components/GalleryResa';
import styles from '../Reservation.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserGroup, faUsers, faArrowUpRightFromSquare, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Circles } from 'react-loader-spinner';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const usePriceCalculation = (paymentRules, guestsInfo, numberOfNights, offert, guestId) => {
  // Logique existante pour calculer totalAmount
  let totalAmount = 0;

  if (offert === true || offert === "true") {
    return { totalAmount: 0 };
  }

  if (!paymentRules || !guestsInfo || typeof numberOfNights !== 'number' || numberOfNights <= 0) {
    return { totalAmount }; // retourne 0 si les données ne sont pas valides
  }

  const hasFamilyRule = paymentRules.some(rule => rule.appliesTo === 'adultsAndChildren');
  const hasChildrenOnlyRule = paymentRules.some(rule => rule.appliesTo === 'childrenOnly');
  const hasOtherGroupsRule = paymentRules.some(rule => rule.appliesTo === 'otherGroups');
  const childrenFreeStay = paymentRules.some(rule => rule.appliesTo === 'childrenOfferStay');

  const calculateAmountForGroup = (count, amount, multiplyByNights = false) => {
    return count * amount * (multiplyByNights ? numberOfNights : 1);
  };

  const groupCounts = guestsInfo.reduce((acc, guest) => {
    acc[guest.groupId] = (acc[guest.groupId] || 0) + 1;
    return acc;
  }, {});

  const guestCat = guestId.charAt(0);

  paymentRules.forEach(rule => {
    const amount = rule.amount;
    const multiplyByNights = rule.ruleType === 'perNight';

    Object.entries(groupCounts).forEach(([groupId, count]) => {

      switch (groupId) {
        case '1': // Adults
        case '2': // Adults

          if (hasFamilyRule && rule.appliesTo === 'adultsAndChildren' && guestCat === 'F') {
            totalAmount += calculateAmountForGroup(1, amount, multiplyByNights);
          } else if (rule.appliesTo === 'everyone' && !hasFamilyRule) {

            if (rule.appliesBy === 'couple') {
              const coupleCount = Math.floor(count / 2);
              const singleParentsCount = count % 2;
              totalAmount += calculateAmountForGroup(coupleCount, amount, multiplyByNights); // Apply to couples
              if (singleParentsCount > 0) {
                // Apply for single parent
                totalAmount += calculateAmountForGroup(singleParentsCount, amount, multiplyByNights);
              }

            } else if (rule.appliesBy === 'head') {
              // Applique le montant à chaque adulte individuellement
              totalAmount += calculateAmountForGroup(count, amount, multiplyByNights);
            }
          }
          break;

        case '3': // Singles
          if (rule.appliesTo === 'everyone') {
            totalAmount += calculateAmountForGroup(count, amount, multiplyByNights);
          }
          break;

        case '4': // Children
          if (hasChildrenOnlyRule && rule.appliesTo === 'childrenOnly') {
            totalAmount += calculateAmountForGroup(count, amount, multiplyByNights);
          } else if (!childrenFreeStay && !hasChildrenOnlyRule && !hasFamilyRule && rule.appliesTo === 'everyone') {
            totalAmount += calculateAmountForGroup(count, amount, multiplyByNights);
          }
          break;

        case '5': // Other Groups
          if (hasOtherGroupsRule && rule.appliesTo === 'otherGroups') {
            totalAmount += calculateAmountForGroup(1, amount, multiplyByNights);
          } else if (!hasOtherGroupsRule && rule.appliesTo === 'everyone') {
            if (rule.appliesBy === 'couple') {
              let groupCouples = Math.floor(count / 2);
              let isOdd = count % 2 !== 0;
              totalAmount += calculateAmountForGroup(groupCouples, amount, multiplyByNights);
              if (isOdd) {
                totalAmount += calculateAmountForGroup(1, amount, multiplyByNights);
              }
            } else if (rule.appliesBy === 'head') {
              totalAmount += calculateAmountForGroup(count, amount, multiplyByNights);
            }
          }
          break;
      }
    });
  });

  return { totalAmount };
};

const ReservationPage = () => {
  const query = useQuery();
  const [urlVersion, setUrlVersion] = useState(0);
  const [guestInfo, setGuestInfo] = useState({});
  const [guestInfoLength, setGuestInfoLength] = useState(null);
  const [guestGroup, setGuestGroup] = useState({});
  const [pricing, setPricing] = useState({});
  const [numberOfNights, setNumberOfNights] = useState({});
  const [custGroupName, setCustGroupName] = useState({});
  const [venueInfos, setVenueInfos] = useState({});
  const [venueEquip, setVenueEquip] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [nomProjet, setNomProjet] = useState(null);
  const [venueId, setVenueId] = useState(null);
  const [isAvailable, setIsAvailable] = useState(true);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedRoomDetails, setSelectedRoomDetails] = useState(null);
  const [initialRoomsData, setInitialRoomsData] = useState([]);
  const [alternativeRoomsData, setAlternativeRoomsData] = useState([]);
  const [reservedRoomIds, setReservedRoomIds] = useState([]);
  const [extraBedCost, setExtraBedCost] = useState(null);
  const [totalBedsInVenue, setTotalBedsInVenue] = useState(null);
  const webSocketRef = useRef(null);
  const [roomsSelectedByOthers, setRoomsSelectedByOthers] = useState({});
  const [roomsReservedByOthers, setRoomsReservedByOthers] = useState({});
  const [roomsCanceledByOther, setRoomsCanceledByothers] = useState({});
  const [roomsConfirmedByOther, setRoomsConfirmedByothers] = useState({});
  const [lastRoomSelectedByAnother, setLastRoomSelectedByAnother] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const venueName = venueInfos[0]?.name;
  const website = venueInfos[0]?.website;
  const description = venueInfos[0]?.description;
  const latitude = venueInfos[0]?.latitude;
  const longitude = venueInfos[0]?.longitude;
  const guestId = query.get('guestId');
  const userId = query.get('userId');
  const projectId = query.get('projectId');
  const buildingId = query.get('buildingId');
  const offert = query.get('offert');
  const paymentCompleted = query.get('payment-completed');
  const paymentCanceled = query.get('payment-canceled');
  const roomCanceled = query.get('roomId');
  const roomConfirmed = query.get('roomId');
  const apiKey = process.env.REACT_APP_MAPBOX_API_KEY;
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const wsUrl = process.env.REACT_APP_WEBSOCKET_URL
    const ws = new WebSocket(wsUrl);
    webSocketRef.current = ws;

    ws.onopen = () => {

      ws.send(JSON.stringify({
        action: 'associateProjectId',
        projectId: projectId
      }));
    };

    ws.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        if (message.projectId !== projectId) return;

        switch (message.type) {
          case 'roomConsultation':
            setRoomsSelectedByOthers(prevState => ({
              ...prevState,
              [message.roomId]: true
            }));
            break;
          case 'roomReservation':
            setRoomsReservedByOthers(prevState => ({
              ...prevState,
              [message.roomId]: true
            }));
            setRoomsSelectedByOthers(prevState => ({
              ...prevState,
              [message.roomId]: false
            }));
            break;
          case 'roomCanceled':
            setRoomsCanceledByothers(prevState => ({
              ...prevState,
              [message.roomId]: true
            }));
            break
          case 'roomConfirmed':
            setRoomsConfirmedByothers(prevState => ({
              ...prevState,
              [message.roomId]: true
            }));
            break
          default:
            console.log('Type de message non reconnu:', message.type);
        }
      } catch (error) {
        console.error('Erreur lors de la lecture du message WebSocket:', error);
        console.log('Données reçues:', event.data);
      }
    };

    ws.onclose = (event) => {
      console.log(`Connexion WebSocket fermée, code: ${event.code}, raison: ${event.reason}`);
    };

    ws.onerror = (error) => {
      console.error('Erreur WebSocket', error);
    };

    return () => {
      ws.close();
    };
  }, [projectId]);

  useEffect(() => {
    const verifyOffert = async () => {
      try {
        if (!userId || !projectId || !guestId || offert === undefined) {
          console.error("Paramètres insuffisants pour vérifier l'offert.");
          return;
        }
        const offertValidity = await getPublicOfferBool(userId, projectId, guestId, offert);

        if (!offertValidity.isValid) {
          alert('Incohérence détectée. L\'URL sera corrigée.');

          const queryParameters = [
            `userId=${userId}`,
            `projectId=${projectId}`,
            `guestId=${guestId}`,
            `offert=${offertValidity.expectedOffert}`
          ];

          if (buildingId) {
            queryParameters.push(`prebooked=true&buildingId=${buildingId}`);
          }

          const newUrl = `${window.location.pathname}?${queryParameters.join('&')}`;
          window.history.replaceState({}, '', newUrl);
          window.location.reload();
          setUrlVersion(v => v + 1);

        }
      } catch (error) {
        console.error('Erreur lors de la vérification du paramètre offert:', error);
      }
    };

    if (userId && projectId && guestId && offert) {
      verifyOffert();
    }
  }, [userId, projectId, buildingId, guestId, offert]);

  useEffect(() => {
    const cancelReservation = async () => {
      if (paymentCanceled && roomCanceled) {
        try {
          await deletePublicExistingReservation(userId, projectId, guestId, roomCanceled);

          if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
            webSocketRef.current.send(JSON.stringify({
              action: 'canceledRoom',
              roomId: roomCanceled,
              projectId: projectId
            }));
          } else {
            console.log('WebSocket n\'est pas connecté.');
          }

        } catch (error) {
          console.error('Erreur lors de la suppression de la réservation:', error);
        }
      }
    };

    cancelReservation();
  }, [paymentCanceled, roomCanceled, userId, projectId, guestId]);

  useEffect(() => {
    const confirmReservation = async () => {
      if (paymentCompleted && roomConfirmed) {
        try {
          // const response = await confirmPublicReservation(userId, projectId, guestId, roomConfirmed);
          // if (response.ok) {
            if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
              webSocketRef.current.send(JSON.stringify({
                action: 'confirmedRoom',
                roomId: roomConfirmed,
                projectId: projectId
              }));
            } else {
              console.log('WebSocket n\'est pas connecté.');
            }
          // } else {
          //   throw new Error(response.message);
          // }
        } catch (error) {
          console.error('Erreur lors de la confirmation de la réservation:', error);
        }
      }
    };

    confirmReservation();
  }, [paymentCompleted, roomConfirmed, userId, projectId, guestId]);

  const formatDecimal = (value) => {
    return new Intl.NumberFormat('fr-FR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  useEffect(() => {
    const fetchReservedRoomIds = async () => {
      try {
        const bookedRooms = await getPublicReservedRoomId(projectId);
        setReservedRoomIds(bookedRooms);
      } catch (error) {
        console.error('Erreur lors de la récupération des roomId réservés', error);
      }
    };
    fetchReservedRoomIds();
  }, [projectId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const guestData = await getGuestsInfoResa(userId, projectId, guestId);
        setGuestGroup(guestData.guestsInfo.map(guest => ({ groupId: guest.groupId, count: 1 })));
        setGuestInfo(guestData);
        setCustGroupName(guestData.guestsInfo[0]?.custGroupName)
        setGuestInfoLength(guestData.guestsInfo.length);

        const datesInfo = await getPublicSartEndDates(projectId);
        setStartDate(datesInfo.startDate);

        const pricingInfo = await getPublicConfigs(projectId);
        setPricing(pricingInfo.paymentRules)
        setNumberOfNights(pricingInfo.numberOfNights)

        const projectData = await getPublicDescAndHotels(userId, projectId);
        setNomProjet(projectData.data.description);

        const venueInfo = await fetchSanityVenueInfo(userId, projectId);
        setVenueInfos(venueInfo);

        const venueEquipments = await fetchVenueEquipments(userId, projectId);
        setVenueEquip(venueEquipments);

        const venueDataID = await getPublicVenueID(userId, projectId);
        const venueIdData = venueDataID.data.venueId;
        setVenueId(venueIdData)

        const extraBedCostData = await getExtraBedsPrice(projectId, venueIdData);
        setExtraBedCost(extraBedCostData.extraBedPrice);

        const totalVenueBeds = await getTotalBeds(projectId, venueIdData);
        setTotalBedsInVenue(totalVenueBeds.totalBeds);

        if (reservedRoomIds.length > 0) { // Assurez-vous que reservedRoomIds est défini avant de filtrer les chambres
          const buildingsData = await fetchRoomAssignments({ guestId, userId, projectId, buildingId, offert });

          setInitialRoomsData(buildingsData.resultatInitial);
          setAlternativeRoomsData(buildingsData.resultatAlternatif);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations de réservation', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (reservedRoomIds.length > 0) { // Exécute fetchData seulement si reservedRoomIds est défini
      fetchData();
    }
  }, [userId, projectId, guestId, offert, buildingId, reservedRoomIds]);

  const { totalAmount } = usePriceCalculation(pricing, guestGroup, numberOfNights, offert, guestId);
  // const currencyAmount = formatCurrency(totalAmount);
  const decimalAmount = formatDecimal(totalAmount);

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Circles
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-loading"
          wrapperStyle={{}}
          wrapperClass=""
          color="#5465ff"
        />
      </div>
    );
  }

  const getPrepositionGroup = (Group) => {
    if (typeof Group !== 'string') {
      console.error('Expected a string for Group, but received:', Group);
      return '';
    }

    const lowerCaseGroup = Group.toLowerCase();

    if (["parents", "frères", "soeurs", "sœurs"].some(word => lowerCaseGroup.startsWith(word))) {
      return "aux";
    } else if (lowerCaseGroup.startsWith("famille")) {
      return "à la";
    } else if (["soeur", "sœur", "frère"].some(word => lowerCaseGroup.startsWith(word))) {
      return lowerCaseGroup.startsWith("frère") ? "au" : "à la";
    } else if (lowerCaseGroup.startsWith("couple")) {
      return "au";
    } else {
      return "";
    }
  };

  const handleRoomSelect = (roomDetails) => {
    setSelectedRoom(roomDetails.roomId);
    setSelectedRoomDetails(roomDetails);

    if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
      webSocketRef.current.send(JSON.stringify({
        action: 'selectRoom',
        roomId: roomDetails.roomId,
        projectId: projectId
      }));

    } else {
      console.log('La connexion WebSocket n\'est pas ouverte ou l\'objet WebSocket n\'est pas défini.');
    }
    setLastRoomSelectedByAnother(roomDetails.roomId);
  };

  const handleOpenModal = (room) => {
    setSelectedRoomDetails(room);
    setIsModalOpen(true);
  };

  const handleReserveRoom = async (roomDetails) => {
    if (!roomDetails) {
      console.error("Détails de la chambre non fournis");
      return;
    }

    const responseIsAvailable = await checkRoomAvailability(projectId, roomDetails.roomId);
    setIsAvailable(responseIsAvailable);

    if (!responseIsAvailable) {
      setErrorMessage('Il semblerait que cette chambre a été réservée entre temps. Merci de rafraîchir la page afin de voir les chambres encores disponibles.');
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      return;
    }

    const reservationData = {
      userId: userId,
      projectId: projectId,
      venueId: venueId,
      buildingId: roomDetails.buildingId,
      buildingName: roomDetails.buildingName,
      roomId: roomDetails.roomId,
      roomName: roomDetails.name,
      guestId: guestId,
      numberOfGuests: guestInfoLength,
      status: 'reserved',
      roomCapacity: roomDetails.bedsCount,
      totalCapacity: totalBedsInVenue,
      amountPaid: roomDetails.price
    };

    const stripeAmount = totalAmount + (extraBedCost * roomDetails.extraBedsUsed);
    // console.log('stripeAmount:', stripeAmount)

    try {
      await savePublicReservation(userId, projectId, reservationData);

      if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
        webSocketRef.current.send(JSON.stringify({
          action: 'roomReservation',
          roomId: roomDetails.roomId,
          projectId: projectId
        }));
        console.log(`WebSocket envoyé pour réservation de la chambre: ${roomDetails.roomId}`);
      } else {
        console.log('WebSocket n\'est pas connecté.');
      }

      const userData = await getPublicUserInfo(userId);
      if (!userData || !userData.data) {
        console.error("Données utilisateur non trouvées");
        return;
      }

      const { stripeAccountId } = userData.data;

      const stripe = await stripePromise;
      const sessionData = await createCheckoutSession(roomDetails, roomDetails.price, extraBedCost, userId, projectId, guestId, offert, buildingId, stripeAccountId);

      const result = await stripe.redirectToCheckout({ sessionId: sessionData.sessionId });

      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la réservation', error);
      // Gérer l'erreur, par exemple en affichant un message à l'utilisateur
    }
  };

  const prepositionGroup = getPrepositionGroup(custGroupName);
  const ruleType = pricing && pricing.length > 0 ? pricing[0].ruleType : null;

  const DescriptionComponent = ({ description }) => {
    const formattedDescription = description.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));

    return <div>{formattedDescription}</div>;
  };

  const getPrepositionVenue = (venueName) => {
    const lowerCaseLieu = venueName.toLowerCase();

    if (["château", "domaine", "gîte", "manoir", "chantier", "chalet"].some(word => lowerCaseLieu.startsWith(word))) {
      return "au";
    } else if (lowerCaseLieu.startsWith("salle")) {
      return "à la";
    } else if (["espace", "hôtel", "atelier"].some(word => lowerCaseLieu.startsWith(word))) {
      return "à l'";
    } else {
      return "au";
    }
  };

  const prepositionVenue = getPrepositionVenue(venueName);

  return (
    <div>
      <TopBanner>
        <h1 className={styles.reservationH1}>Bienvenue {prepositionGroup} {custGroupName} !</h1>
        <div className={styles.reservationH3}>
          <div className={styles.iconsAndTextContainer}>
            <div className={styles.iconWithText}>
              {guestInfoLength === 1 && <FontAwesomeIcon icon={faUser} style={{ color: "#fff" }} />}
              {guestInfoLength === 2 && <FontAwesomeIcon icon={faUserGroup} style={{ color: "#fff" }} />}
              {guestInfoLength > 2 && <FontAwesomeIcon icon={faUsers} style={{ color: "#fff" }} />}
              <h1 className={styles.numberGuests}>{guestInfoLength}</h1>
            </div>
            <div className={styles.iconWithText}>
              <IconForNights ruleType={ruleType} amount={decimalAmount} numberOfNights={numberOfNights} />
            </div>
          </div>
        </div>
      </TopBanner>
      <h2 className={styles.reservationH2}>
        Vous êtes attendus au {nomProjet} dans ...
      </h2>
      <CountdownTimer
        endDate={startDate}>
      </CountdownTimer>
      {paymentCompleted && (
        <div>
          <h1
            className={styles.felicitations}
          >
            Félicitations ! <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#28b97b", marginLeft: "10px" }} />
          </h1>
          <p className={styles.congratsP}>Vous avez réservé votre chambre, félicitations ! Si l'attente est trop longue n'hésitez pas à revenir ici pour consulter les informations utiles à connaître avant de se rendre sur place.</p>
          <p className={styles.congratsP}>Vous retrouverez toutes les informations liées à votre paiement dans l'email de confirmation.</p>
        </div>
      )}
      <div className={styles.venueContainer}>
        <div className={styles.venueInfo}>
          <h2 className={styles.venuName}>
            ... {prepositionVenue} {venueName}
            <a href={website} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="sm" className={styles.customLaunchIcon} />
            </a>
          </h2>
          <DescriptionComponent description={description} />
          <VenueDetails venueEquip={venueEquip} />
        </div>
        <div className={styles.venueMap}>
          <MapContainer center={[latitude, longitude]} zoom={13} scrollWheelZoom={false} style={{ height: '100%', width: '100%' }}>
            <TileLayer
              url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
              attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>'
              id="mapbox/streets-v11"
              accessToken={apiKey}
            />
            <Popup position={[latitude, longitude]}>
              <a href={`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`} target="_blank" rel="noopener noreferrer">
                Prendre l'autoroute de l'amour ❤️ !
              </a>
            </Popup>
          </MapContainer>
        </div>
      </div>
      <Gallery userId={userId} projectId={projectId} />
      {!paymentCompleted && (
        <>
          <h1 className={styles.resultatsChambresH1}>Meilleures chambres trouvées pour vous</h1>
          <div className={styles.roomsContainer}>
            {initialRoomsData.map(room => (
              <div className={styles.roomCard} key={room.roomId}>
                {room.isSuite ? (
                  <SuiteCard
                    key={room.roomId}
                    suite={room}
                    isSelected={room.roomId === selectedRoom}
                    onSelect={() => handleRoomSelect(room)}
                    isSelectedByAnother={!!roomsSelectedByOthers[room.roomId]}
                    isReservedByAnother={!!roomsReservedByOthers[room.roomId]}
                    isCanceledByAnother={!!roomsCanceledByOther[room.roomId]}
                    isConfirmedByAonther={!!roomsConfirmedByOther[room.roomId]}
                    extraBedCost={extraBedCost}
                    amount={totalAmount}
                    // handleReserveRoom={getReserveRoomHandler(room)}
                    onOpenModal={handleOpenModal}
                  />
                ) : (
                  <RoomCard
                    key={room.roomId}
                    room={room}
                    isSelected={room.roomId === selectedRoom}
                    onSelect={() => handleRoomSelect(room)}
                    isSelectedByAnother={!!roomsSelectedByOthers[room.roomId]}
                    isReservedByAnother={!!roomsReservedByOthers[room.roomId]}
                    isCanceledByAnother={!!roomsCanceledByOther[room.roomId]}
                    isConfirmedByAonther={!!roomsConfirmedByOther[room.roomId]}
                    extraBedCost={extraBedCost}
                    amount={totalAmount}
                    // handleReserveRoom={getReserveRoomHandler(room)}
                    onOpenModal={handleOpenModal}
                  />
                )}
              </div>
            ))}
          </div>
          {alternativeRoomsData && alternativeRoomsData.length > 0 && (
            <>
              <h1 className={styles.resultatsChambresH1}>Autres chambres qui pourraient vous convenir</h1>
              <p className={styles.resultatsChambreP}>Vous n'avez pas trouvé votre bonheur ? Peut-être que ces autres propositions de chambres pourraient mieux vous convenir :</p>
              <div className={styles.roomsContainer}>
                {alternativeRoomsData.map(room => (
                  <div className={styles.roomCard} key={room.roomId}>
                    {room.isSuite ? (
                      <SuiteCard
                        key={room.roomId}
                        suite={room}
                        isSelected={room.roomId === selectedRoom}
                        onSelect={() => handleRoomSelect(room)}
                        isSelectedByAnother={roomsSelectedByOthers[room.roomId]}
                        isReservedByAnother={!!roomsReservedByOthers[room.roomId]}
                        isCanceledByAnother={!!roomsCanceledByOther[room.roomId]}
                        isConfirmedByAonther={!!roomsConfirmedByOther[room.roomId]}
                        extraBedCost={extraBedCost}
                        amount={totalAmount}
                        // handleReserveRoom={getReserveRoomHandler(room)}
                        onOpenModal={handleOpenModal}
                      />
                    ) : (
                      <RoomCard
                        key={room.roomId}
                        room={room}
                        isSelected={room.roomId === selectedRoom}
                        onSelect={() => handleRoomSelect(room)}
                        isSelectedByAnother={roomsSelectedByOthers[room.roomId]}
                        isReservedByAnother={!!roomsReservedByOthers[room.roomId]}
                        isCanceledByAnother={!!roomsCanceledByOther[room.roomId]}
                        isConfirmedByAonther={!!roomsConfirmedByOther[room.roomId]}
                        extraBedCost={extraBedCost}
                        amount={totalAmount}
                        // handleReserveRoom={getReserveRoomHandler(room)}
                        onOpenModal={handleOpenModal}
                      />
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )};
      <ReservationModal
        isOpen={isModalOpen}
        roomDetails={selectedRoomDetails}
        price={totalAmount}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleReserveRoom}
        extraBedCost={extraBedCost}
        message={errorMessage}
      />
    </div>
  );
};

export default ReservationPage;
