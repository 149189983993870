const predefinedColors = ['#2d004b', '#ffffff', '#071015', '#5465ff'];

// Function to get a random color from the predefined colors
export const getRandomPredefinedColor = () => {
  return predefinedColors[Math.floor(Math.random() * predefinedColors.length)];
};

// Function to get the text color based on the background color
export const getTextColor = (bgColor) => {
  const r = parseInt(bgColor.slice(1, 3), 16);
  const g = parseInt(bgColor.slice(3, 5), 16);
  const b = parseInt(bgColor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? 'black' : 'white';
};

// Function to determine if a color is light
export const isLight = (color) => {
  if (!color) {
    return true; // Default preference
  }
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  return (r * 0.299 + g * 0.587 + b * 0.114) > 186;
};

// Function to get a stroke color based on the background color
export const getStrokeColor = (bgColor) => {
  return isLight(bgColor) ? 'black' : 'white';
};

// Function to get a contrasting color for borders
export const getBorderColor = (bgColor) => {
  return isLight(bgColor) ? '#000000' : '#FFFFFF';
};