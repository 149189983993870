import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { updateVenue, updateProjectStatus, getCurrentUser } from '../api/api';
import WeddingMapComp from '../components/MapContainer';
import ModalVenue from '../components/ModalVenue';
import '../VenueSelection.css';

const VenueSelection = () => {
  const [venue, setVenue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const history = useHistory();
  const { projectId } = useParams();

  const handleSave = async () => {
    const userResponse = await getCurrentUser();
    const userId = userResponse.data._id;
    const response = await updateVenue(userId, projectId, venue);
    if (response.status === 200) {
      const updateVenueStatus = updateProjectStatus(userId, projectId, 'venueStatus', 'Terminé');
      const updateGuestListStatus = updateProjectStatus(userId, projectId, 'guestListStatus', 'En cours');
  
      const [VenueCustStatusUpdated] = await Promise.all([updateVenueStatus, updateGuestListStatus]);
      // await updateProjectStatus(userId, projectId, 'venueStatus', 'Terminé');
      if (VenueCustStatusUpdated.status === 200){
        history.push(`/creer/depot-fichier/${projectId}`);
      } else {
        console.log("Erreur lors de la mise à jour des statuts");
      }
    }
  };

 

  const handleVenueSelect = (venueData) => {
    setVenue(venueData);
    setModalContent({
      title: venueData.name,
      logo: venueData.logo,
      alt: venueData.logo.alt,
      description: venueData.description,
      gallery: venueData.gallerie
    });
    setIsModalOpen(true);
  };

  const handleVenueNotFound = () => {
    setModalContent({
      title: "Lieu non trouvé",
      description: (
        <p>
          Ce lieu n'est pas encore proposé par Your-Guests. Vous pouvez demander de l'ajouter :
          <a href="/contact" className="contact-link">Nous contacter</a>.
        </p>
      ),
      // Autres contenus pour le cas "non trouvé"
    });
    setIsModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="venue-container">

      <ModalVenue
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={modalContent}
        onSave={handleSave}
      />
      <h1
        className="venue-header"
      >
        Choisissez le lieu de votre mariage
      </h1>
      <p className="mb-4">Recherchez le lieu de votre mariage par mot-clé (ex : Château de Carsix). Si vous ne trouvez pas le lieu, <a href="/contact" className="contact-link">contactez-nous</a>.</p>
      <WeddingMapComp
        handleVenueSelect={handleVenueSelect}
        handleVenueNotFound={handleVenueNotFound}
        openModal={openModal}
      />
      <div className='button-suivant'>
        <button
          className="buttonS"
          onClick={handleSave}
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default VenueSelection;