import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { getGuestsInfoResa, cancelReservation, getReservationId, getAmountPaid, resetPublicCancelledReservation, getPublicSartEndDates } from '../api/api';
import { fetchRoomDetails, fetchSanityVenueInfo, fetchVenueEquipments } from '../components/ReservationHelpers';
import Modal from 'react-modal';
import CommentInputCancel from '../components/CommentCancellation';
import DonationInput from '../components/DonationInput';
import TopBanner from '../components/ResaTopBanner';
import Carousel from 'react-slick';
import CarousselArrows from '../components/CarousselArrows';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { RiRefund2Line } from 'react-icons/ri';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../Slider.css';
import { Circles } from 'react-loader-spinner';
import '../Spinner.css';
import styles from '../BookedRoom.module.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BookedRoom = () => {
  const query = useQuery();
  const history = useHistory();
  const guestId = query.get('guest');
  const projectId = query.get('project');
  const userId = query.get('user');
  const roomId = query.get('room');
  const cancellationCompleted = query.get('payment-completed');
  const cancellationCanceled = query.get('payment-canceled');
  const refundCompletedQuery = query.get('refund-completed'); 
  const urlReservationId = query.get('reservationId');

  const [reservationId, setReservationId] = useState(null);
  const [reservationStatus, setReservationStatus] = useState(null);
  const [error, setError] = useState(null);
  const [custGroupName, setCustGroupName] = useState('');
  const [venueInfos, setVenueInfos] = useState({});
  const [venueEquip, setVenueEquip] = useState({});
  const [roomDetails, setRoomDetails] = useState(null);
  const [buildingImages, setBuildingImages] = useState([]);
  const [buildingName, setBuildingName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [comment, setComment] = useState('');
  const [donationAmount, setDonationAmount] = useState(0);
  const [initialAmount, setInitialAmount] = useState(0);
  const [cancellationCompletedState, setCancellationCompleted] = useState(false);
  const [refundCompleted, setRefundCompleted] = useState(false);
  const [changeRoomCompleted, setChangeRoomCompleted] = useState(false);
  const [isCancellationAllowed, setIsCancellationAllowed] = useState(true);
  const [commentCols, setCommentCols] = useState(50);
  const [modalStyle, setModalStyle] = useState({
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 9999,
      minWidth: '25%',
      minHeight: '58%',
      maxHeight: '90%',
      maxWidth: '45%',
      overflow: 'auto',
      borderRadius: '10px',
      color: '#000000',
      paddingTop: '0px',
      paddingRight: '20px',
      paddingLeft: '20px',
      paddingBottom: '20px'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9998
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { reservationId, status } = await getReservationId(userId, projectId, guestId);
        setReservationId(reservationId || urlReservationId);
        setReservationStatus(status);
    
        const { amountPaid } = await getAmountPaid(reservationId || urlReservationId);
        setInitialAmount(amountPaid);
    
        const guestData = await getGuestsInfoResa(userId, projectId, guestId);
        console.log('guestData', guestData);
        setCustGroupName(guestData.guestsInfo[0]?.custGroupName || '');
    
        const venueInfo = await fetchSanityVenueInfo(userId, projectId);
        setVenueInfos(venueInfo);
    
        const venueEquipments = await fetchVenueEquipments(userId, projectId);
        setVenueEquip(venueEquipments);
    
        const roomData = await fetchRoomDetails(roomId);
    
        if (roomData.length > 0) {
          const building = roomData.find(building => (building.rooms && building.rooms.length > 0) || (building.suites && building.suites.length > 0));
          if (building) {
            const room = building.rooms.length > 0 ? building.rooms[0] : building.suites[0].roomsInSuite[0];
            setRoomDetails(room);
            setBuildingImages(building.buildingImgUrls);
            setBuildingName(building.buildingName);
          } else {
            setRoomDetails(null);
            setBuildingImages([]);
          }
        } else {
          setRoomDetails(null);
          setBuildingImages([]);
        }

        const pricingModel = await getPublicSartEndDates(projectId);
        const eventStartDate = new Date(pricingModel.startDate);

        // Check if cancellation is allowed (current time is before 00:01 on the event day)
        const now = new Date();
        const cutOffDate = new Date(eventStartDate);
        cutOffDate.setHours(0, 1, 0, 0); // 00:01 on the event start date

        if (now >= cutOffDate) {
          setIsCancellationAllowed(false);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations de réservation', error);
        setError('Erreur lors de la récupération des informations de réservation');
      } finally {
        setIsLoading(false);
      }
    };

    
      fetchData();
      if (urlReservationId) {
        setReservationId(urlReservationId);
        setIsLoading(false);
      }
      
  }, [projectId, guestId, roomId, userId, cancellationCompleted, cancellationCanceled, refundCompletedQuery, urlReservationId]);

  useEffect(() => {
    const cancelReservation = async () => {
      if (cancellationCanceled) {
        try {
          await resetPublicCancelledReservation(reservationId);
        } catch (error) {
          console.error('Erreur lors de la remise à zéro de la réservation:', error);
        }
      }
    };

    cancelReservation();
  }, [cancellationCanceled, userId, projectId, guestId, reservationId]);

  useEffect(() => {
    if (cancellationCompleted) {
      setCancellationCompleted(true);
    }
  }, [cancellationCompleted]);

  useEffect(() => {
    if (refundCompletedQuery) {
      setRefundCompleted(true);
    }
  }, [refundCompletedQuery]);

  useEffect(() => {
    if (query.get('offert')) {
      setChangeRoomCompleted(true);
    }
  }, [query]); 

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 450) {
        setModalStyle(prevStyle => ({
          ...prevStyle,
          content: {
            ...prevStyle.content,
            maxWidth: '85%'
          }
        }));
        setCommentCols(35);
      } else if (window.innerWidth <= 768) {
        setModalStyle(prevStyle => ({
          ...prevStyle,
          content: {
            ...prevStyle.content,
            maxWidth: '95%'
          }
        }));
        setCommentCols(45);
      }else if (window.innerWidth <= 1200) {
        setModalStyle(prevStyle => ({
          ...prevStyle,
          content: {
            ...prevStyle.content,
            maxWidth: '75%'
          }
        }));
        setCommentCols(50);
      } else {
        setModalStyle(prevStyle => ({
          ...prevStyle,
          content: {
            ...prevStyle.content,
            maxWidth: '45%'
          }
        }));
        setCommentCols(50);
      }
    };

    handleResize(); // Check the initial window size
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCancelClick = () => {
    setShowModal(true);
  };

  const handleConfirmCancelClick = (option) => {
    setSelectedOption(option);
    setShowConfirmModal(true);
    setShowModal(false);
  };

  const handleFinalCancellation = async () => {
    try {
      let response;
      if (selectedOption === 'full-donation' && donationAmount > initialAmount) {
        const additionalAmount = donationAmount - initialAmount;
        response = await cancelReservation(reservationId, selectedOption, additionalAmount, comment);
        if (response.success && response.sessionUrl) {
          window.location.href = response.sessionUrl; // Redirect to the checkout session
          return;
        }
      } else if (selectedOption === 'full-donation' && donationAmount === 0) {
        // console.log('initialAmount: ', initialAmount)
        response = await cancelReservation(reservationId, selectedOption, 0, comment);
        // console.log('in full-donation witout donation response: ', response);
        // console.log('in full-donation witout donation response.success: ', response.success);
        if (response.success) {
          setShowConfirmModal(false);
          history.push(`/booked-room?user=${userId}&project=${projectId}&room=${roomId}&guest=${guestId}&payment-completed=true&reservationId=${reservationId}`);
          return;
        }
      } else {
        response = await cancelReservation(reservationId, selectedOption, 0, comment);
        if (response.success && selectedOption === 'partial-refund') {
          setRefundCompleted(true);
          setShowConfirmModal(false);
          history.push(`/booked-room?user=${userId}&project=${projectId}&room=${roomId}&guest=${guestId}&refund-completed=true&reservationId=${reservationId}`);          
        } else if (response.success && selectedOption === 'change-room') {
          const url = `/reservations?userId=${userId}&projectId=${projectId}&guestId=${guestId}&offert=true&reservationId=${reservationId}`;
          history.push(url);
        }
      }
    } catch (error) {
      console.error('Erreur lors de l\'annulation de la réservation', error);
    }
  };
  
  const handleCommentChange = (newComment) => {
    setComment(newComment);
  };

  const handleDonationAmountChange = (newAmount) => {
    setDonationAmount(newAmount);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CarousselArrows direction="next" />,
    prevArrow: <CarousselArrows direction="prev" />
  };

  const getPrepositionVenue = (venueName) => {
    const lowerCaseLieu = venueName.toLowerCase();
    if (["château", "domaine", "gîte", "manoir", "chantier", "chalet"].some(word => lowerCaseLieu.startsWith(word))) {
      return "au";
    } else if (lowerCaseLieu.startsWith("salle")) {
      return "à la";
    } else if (["espace", "hôtel", "atelier"].some(word => lowerCaseLieu.startsWith(word))) {
      return "à l'";
    } else {
      return "au";
    }
  };

  if (isLoading) {
    return (
      <div className="loadingContainer">
        <Circles
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-loading"
          wrapperStyle={{}}
          wrapperClass=""
          color="#5465ff"
        />
      </div>
    );
  }
  if (error) {
    return <div>{error}</div>;
  }

  const venueName = venueInfos[0]?.name;
  const prepositionVenue = venueName ? getPrepositionVenue(venueName) : '';

  return (
    <div className={styles.mainContainer}>
      {(cancellationCompletedState || refundCompleted || changeRoomCompleted) ? (
        <>
          <TopBanner>
            <h1 className={styles.bookedRoomH1}>
              Merci, {custGroupName} !
            </h1>
          </TopBanner>

          <div>
            <h1 className={styles.felicitations}>
              Votre réservation a été annulée ! <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#28b97b", marginLeft: "10px" }} />
            </h1>
            {cancellationCompletedState && (
              <p className={styles.congratsP}>Nous avons bien pris en compte votre généreuse donation.</p>
            )}
            {refundCompleted && (
              <p className={styles.congratsP}>Votre remboursement a été traité.</p>
            )}
            {changeRoomCompleted && (
              <p className={styles.congratsP}>Vous avez changé de chambre avec succès.</p>
            )}
            <p className={styles.congratsP}>A bientôt sur Your guests</p>
          </div>
        </>
      ) : (
        <>
          <TopBanner>
            <h1 className={styles.bookedRoomH1}>
              Vous revoilà, {custGroupName} !
            </h1>
          </TopBanner>

          {venueName && (
            <h2 className={styles.bookedRoomH2}>
              Vous retrouverez ci-dessous votre réservation {prepositionVenue} {venueName}
            </h2>
          )}

          <div className={styles.detailsContainer}>
            {roomDetails ? (
              <>
                <div>
                  <h3 className={styles.bookedRoomH3}>
                    Votre chambre : {roomDetails.name} - {buildingName}
                  </h3>
                  {roomDetails.roomImgUrls && roomDetails.roomImgUrls.length > 0 ? (
                    <div className={styles.roomCardImageContainerModalCarousel}>
                      <Carousel {...settings}>
                        {roomDetails.roomImgUrls.map((image, index) => (
                          <div key={`${image}-${index}`} className={styles.roomCardImageContainerModalCarousel}>
                            <img src={image} alt={`Building image ${index + 1}`} className={`${styles.image} ${styles.roomCardCarouselImg}`} />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  ) : buildingImages.length > 0 ? (
                    <div className={styles.roomCardImageContainerModalCarousel}>
                      <Carousel>
                        {buildingImages.map((image, index) => (
                          <div key={`${image}-${index}`} className={styles.roomCardImageContainerModalCarousel}>
                            <img src={image} alt={`Building image ${index + 1}`} className={`${styles.image} ${styles.roomCardCarouselImg}`} />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  ) : (
                    <div>Aucune image disponible pour cette chambre ou bâtiment.</div>
                  )}
                </div>
                <div>
                  <h3 className={styles.bookedRoomH3}>Équipement de la chambre</h3>
                  {roomDetails.equipment && roomDetails.equipment.length > 0 ? (
                    <ul>
                      {roomDetails.equipment.map(equip => (
                        <li key={equip._id}>
                          <img src={equip.iconUrl} alt={equip.title} />
                          {equip.title}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div>Aucun équipement disponible pour cette chambre.</div>
                  )}
                </div>
              </>
            ) : (
              <div>Aucune chambre trouvée.</div>
            )}
          </div>
          {isCancellationAllowed ? (
              <div className={styles.cancellationContainer}>
                <p className={styles.bookedRoomP}>Vous ne pouvez plus venir, ou vous devez changer de chambre ?</p>
                <button
                  className={styles.buttonCancel}
                  onClick={handleCancelClick}
                >
                  Annuler la réservation
                </button>
              </div>
            ) : (
              <div className={styles.cancellationContainer}>
                <p className={styles.bookedRoomP}>Les annulations ne sont pas autorisées après la date de l'événement.</p>
              </div>
            )}

          </>
      )}

      {showModal && (
        <Modal
          isOpen={true}
          onRequestClose={() => setShowModal(false)}
          style={modalStyle}
        >
          <div className={styles.modalContainer}>
            <h1 className={styles.modalCancelTitle}>
              Annulation de votre réservation
            </h1>
            <p className={styles.modalDescription}>
              Ici, vous pouvez annuler votre chambre de 3 manières :
            </p>
            <ul className={styles.modalList}>
              <li>
                <span className={styles.changeRoomText}>Changement de chambre :</span> vous pouvez sélectionner une autre chambre correspondant au même prix que celle que vous avez payée. La chambre que vous avez initialement réservée sera rendue libre et proposée aux autres invités.
              </li>
              <li>
                <span className={styles.refundText}>Demander un remboursement :</span> la chambre que vous avez réservée sera rendue disponible pour les autres invités et nous vous remboursons le prix de la chambre (nous ne remboursons pas la commission prélevée lors de la transaction).
              </li>
              <li>
                <span className={styles.donationText}>Offrir :</span> la chambre que vous avez réservée sera rendue disponible pour les autres invités et vous faites le choix de faire un don aux mariés du montant de votre réservation. Vous pouvez, bien sûr, offrir plus, mais pas moins.
              </li>
            </ul>
            <button
              className={`${styles.buttonAction} ${styles.buttonChangeRoom}`}
              onClick={() => handleConfirmCancelClick('change-room')}
            >
              <MdOutlineChangeCircle className={styles.buttonIcon} /> Changer de chambre
            </button>
            <button
              className={`${styles.buttonAction} ${styles.buttonRefund}`}
              onClick={() => handleConfirmCancelClick('partial-refund')}
            >
              <RiRefund2Line className={styles.buttonIcon} /> Demander un remboursement
            </button>
            <button
              className={`${styles.buttonAction} ${styles.buttonDonation}`}
              onClick={() => handleConfirmCancelClick('full-donation')}
            >
              <FaHandHoldingHeart className={styles.buttonIcon} /> Offrir
            </button>
            <button
              className={styles.buttonClose}
              onClick={() => setShowModal(false)}
            >
              Fermer
            </button>
          </div>
        </Modal>
      )}

      {showConfirmModal && (
        <Modal
          isOpen={true}
          onRequestClose={() => setShowConfirmModal(false)}
          style={modalStyle}
        >
          <div className={styles.modalContainer}>
            <h1 className={styles.modalCancelTitle}>
              Attention
            </h1>
            <p className={styles.modalDescription}>
              Cette action ne permettra pas de revenir en arrière. L'annulation de cette chambre sera définitive. Continuer ?
            </p>
            {selectedOption === 'full-donation' && (
              <DonationInput initialAmount={initialAmount} onAmountChange={handleDonationAmountChange} />
            )}
            <CommentInputCancel onCommentChange={handleCommentChange} cols={commentCols}/>
            <button
              className={styles.buttonAction}
              onClick={handleFinalCancellation}
            >
              Confirmer l'annulation
            </button>
            <button
              className={styles.buttonClose}
              onClick={() => setShowConfirmModal(false)}
            >
              Revenir en arrière
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BookedRoom;
