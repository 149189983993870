import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Carousel from 'react-slick';
import CarousselArrows from './CarousselArrows';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPlus, faMinus, } from '@fortawesome/free-solid-svg-icons';
import { GrCircleInformation, GrFormDown, GrFormUp } from "react-icons/gr";
import styles from '../ReservationModal.module.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../Slider.css';

const ReservationModal = ({ isOpen, roomDetails, price, onClose, onConfirm, extraBedCost, message }) => {
  const [extraBeds, setExtraBeds] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [pmrFlag, setPmrFlag] = useState(false);
  const [imagesToShow, setImagesToShow] = useState([]);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [isTotalPriceTooltipVisible, setIsTotalPriceTooltipVisible] = useState(false);
  const [modalStyle, setModalStyle] = useState({
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 9999,
      minWidth: '25%',
      minHeight: '58%',
      maxHeight: '90%',
      maxWidth: '45%',
      overflow: 'auto',
      borderRadius: '10px',
      color: '#000000',
      paddingTop: '0px',
      paddingRight: '20px',
      paddingLeft: '20px',
      paddingBottom: '20px'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9998
    }
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setModalStyle(prevStyle => ({
          ...prevStyle,
          content: {
            ...prevStyle.content,
            maxWidth: '95%'
          }
        }));
      } else if (window.innerWidth <= 1200) {
        setModalStyle(prevStyle => ({
          ...prevStyle,
          content: {
            ...prevStyle.content,
            maxWidth: '75%'
          }
        }));
      } else {
        setModalStyle(prevStyle => ({
          ...prevStyle,
          content: {
            ...prevStyle.content,
            maxWidth: '45%'
          }
        }));
      }
    };

    handleResize(); // Check the initial window size
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (roomDetails) {
      setExtraBeds(roomDetails.extraBedsUsed || 0);
      setTotalPrice(price + (roomDetails.extraBedsUsed || 0) * extraBedCost);

      const availableImages = roomDetails.roomImgUrl ? roomDetails.roomImgUrl : roomDetails.buildingImgUrl;
      setImagesToShow(availableImages);

      setPmrFlag(roomDetails.flags?.find(flag => flag.title === "PMR"));
    }
  }, [roomDetails, price, extraBedCost]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CarousselArrows direction="next" />,
    prevArrow: <CarousselArrows direction="prev" />
  };

  const fees = '10,45%';
  const feesNumber = fees.replace('%', '');
  const feesPercentage = parseFloat(fees.replace(',', '.').replace('%', '')) / 100;

  // Calculate the total amount including the fees
  const totalAmount = totalPrice * (1 + feesPercentage);

  const formattedTotalAmount = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }).format(totalAmount);

  const getFloorLabel = (floor) => {
    if (!floor) return ''; // Retourne une chaîne vide si floor n'est pas défini
    return floor === 'rdc' ? 'Rdc' : `${floor} étage`;
  };

  const getSuiteFloor = (roomDetails) => {
    return roomDetails.roomsInSuite && roomDetails.roomsInSuite.length > 0
      ? getFloorLabel(roomDetails.roomsInSuite[0].floor)
      : '';
  };

  const handleExtraBedChange = (increment) => {
    if (roomDetails) {
      setExtraBeds(roomDetails.extraBedsUsed);
      const newExtraBeds = Math.max(0, extraBeds + increment); // S'assure que le nombre ne descend pas en dessous de 0
      setExtraBeds(newExtraBeds);
      setTotalPrice(price + newExtraBeds * extraBedCost);
    } else {
      console.log('en attente des données de chambre')
    }
  };

  const handleSubmit = async () => {
    const updatedRoomDetails = {
      ...roomDetails,
      extraBedsUsed: extraBeds,
      price: price,
    };
    await onConfirm(updatedRoomDetails); // Appel à onConfirm avec les détails de la chambre mis à jour
    if (message === '') {
      onClose(); // Ferme le modal seulement si la chambre est disponible
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyle}
    >
      <div className={styles.modalHeader}>
        <button onClick={onClose} className={styles.closeButton}>
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '24px' }} />
        </button>
        <h2 className={styles.modalTitle}>Votre récapitulatif</h2>
      </div>
      <div>

        {roomDetails ? (
          <>
            <div className={styles.carouselContainer}>
              {imagesToShow && imagesToShow.length > 0 && (
                <>
                  {imagesToShow.length === 1 ? (
                    <div className={styles.roomCardImageContainerModal}>
                      <div className={styles.imageContainer}>
                        <img src={imagesToShow[0]} className={`${styles.image} ${styles.roomCardNoCarousel}`} />
                      </div>
                    </div>
                  ) : (
                    <div className={styles.roomCardImageContainerModalCarousel}>
                      <Carousel {...settings}>
                        {imagesToShow.map((imgUrl, index) => (
                          <div key={`${imgUrl}-${index}`} className={styles.roomCardImageContainerModalCarousel}>
                            <img src={imgUrl} className={`${styles.image} ${styles.roomCardCarouselImg}`} />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styles.modalBody}>
              <div className={styles.roomBuildingName}>
                <h3 className={styles.detailsH2}>
                  {roomDetails.name} {pmrFlag && <img src={pmrFlag.iconUrl} alt="Chambre PMR" title="Chambre PMR" style={{ width: '20px', height: '20px', marginLeft: '5px', marginRight: '5px' }} />} - {roomDetails.roomsInSuite ? getSuiteFloor(roomDetails) : getFloorLabel(roomDetails.floor)}
                </h3>
                <p>{roomDetails.buildingName}</p>
                <p className={styles.numberPeople}>
                  {roomDetails.beds || roomDetails.bedsCount} personnes
                </p>
              </div>
              <div>

                {roomDetails.roomsInSuite ? (
                  <div>
                    <h4 className={styles.roomsInSuite}>Chambres dans la suite:</h4>
                    <ul className={styles.roomInfoInSuite}>
                      {roomDetails.roomsInSuite.map(room => (
                        <li key={room._key} className={styles.roomInSuite}>
                          <span className={styles.roomName}>{room.name}</span>
                          <div className={styles.bedSizes}>
                            {room.bedSize && room.bedSize.map(size => (
                              <div key={size.sizeType._id} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                <span style={{ marginRight: '5px' }}>{size.quantity} x</span>
                                <img src={size.sizeType.iconUrl} alt={size.sizeType.title} title={size.sizeType.title} style={{ width: '2.5em', height: 'auto' }} />
                                <p>{size.quantity > 1 ? "Lits" : "Lit"}</p>
                                <span style={{ marginRight: '5px', marginLeft: '5pX' }}>{size.sizeType.title}</span>
                              </div>
                            ))}
                          </div>
                          {room.equipment && (
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginBottom: '10px' }}>
                              Équipement :
                              {room.equipment && room.equipment.map(equip => (
                                <img key={equip._id} src={equip.iconUrl} alt={equip.title} title={equip.title} style={{ width: '2.5em', height: 'auto', marginLeft: '10px' }} />
                              ))}
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className={styles.Beds}>
                    {roomDetails.bedSize && roomDetails.bedSize.map(size => (
                      <div key={size.sizeType._id} className={styles.bedItem}>
                        <span style={{ marginRight: '5px' }}>{size.quantity} x</span>
                        <img src={size.sizeType.iconUrl} alt={size.sizeType.title} title={size.sizeType.title} style={{ width: '2.5em', height: 'auto' }} />
                        <p>{size.quantity > 1 ? "Lits" : "Lit"}</p>
                        <span style={{ marginRight: '5px', marginLeft: '5px' }}>{size.sizeType.title}</span>
                      </div>
                    ))}
                    {roomDetails.equipment && (
                      <div className={styles.equipment}>
                        Équipement :
                        {roomDetails.equipment.map(equip => (
                          <img key={equip._id} src={equip.iconUrl} alt={equip.title} title={equip.title} style={{ marginRight: '15px', width: '2.5em', height: 'auto', marginLeft: '10px' }} />
                        ))}
                      </div>
                    )}
                  </div>
                )}
                <div className={styles.extraBeds}>
                  Lits d'appoint :
                  <button onClick={() => handleExtraBedChange(-1)} disabled={extraBeds <= 0} className={styles.buttonIncrement}>
                    <FontAwesomeIcon icon={faMinus} size="sm" style={{ color: "#071015" }} />
                  </button>
                  <span> {extraBeds} </span>
                  <button onClick={() => handleExtraBedChange(1)} disabled={extraBeds >= 2} className={styles.buttonIncrement}>
                    <FontAwesomeIcon icon={faPlus} size="sm" style={{ color: "#071015" }} />
                  </button>
                  <button onClick={() => setTooltipVisible(!isTooltipVisible)} className={styles.infoButton}>
                    <GrCircleInformation style={{ color: "#ff0000", marginLeft: "10px", cursor: "help" }} />
                    {isTooltipVisible ? <GrFormUp style={{ cursor: "pointer" }} /> : <GrFormDown style={{ cursor: "pointer" }} />}
                  </button>
                </div>
                {isTooltipVisible && (
                  <div className={styles.tooltipText}>
                    Si la chambre proposée contenait déjà au moins 1 lit d'appoint et que vous les avez retirés, Your Guests ne reconnaît aucune responsabilité dans les cas assurantiels qui pourraient survenir. Prenez contact avec vos hôtes pour être sûr de respecter leurs obligations légales.
                  </div>
                )}
              </div>
              <div className={styles.pricingInfo}>
                <p className={styles.fees}>Commission Your Guests: {fees}</p>
                <div className={styles.totalPrice}>
                  Prix total: {formattedTotalAmount}
                  <button onClick={() => setIsTotalPriceTooltipVisible(!isTotalPriceTooltipVisible)} className={styles.infoPriceButton}>
                    <GrCircleInformation style={{ color: "#5465ff", marginLeft: "10px", cursor: "help" }} />
                    {isTotalPriceTooltipVisible ? <GrFormUp style={{ cursor: "pointer" }} /> : <GrFormDown style={{ cursor: "pointer" }} />}
                  </button>
                  {isTotalPriceTooltipVisible && (
                    <div className={styles.tooltipText}>
                      Le prix total inclut les frais de service de {fees}, calculés sur le montant de base de la réservation. <br />
                      Dans votre cas : {totalPrice} + ({totalPrice} x {feesNumber} / 100 )
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.confirmButtonContainer}>
              <button
                onClick={handleSubmit}
                className={styles.button}
              >
                Confirmer et Payer
              </button >
              {message && (
                <div className={styles.errorMessage}>
                  {message}
                </div>
              )}
            </div>
          </>
        ) : (
          <p>Chargement des détails de la chambre...</p>
        )}
      </div>
    </Modal>
  );
};

export default ReservationModal;