import React from 'react';
import { Helmet } from 'react-helmet';
import '../style.css';

// Import de composants
import '../components/BillIcon';

import { useMediaQuery } from '@mui/material';
import Dot from '../components/NavDots';
import ScrollToTop from '../components/ScrollToTop'
import FullPageScroll from '../components/FullPageScroll';
import IntroSection from '../components/IntroSection';
import NosServicesSection from '../components/NosServicesSection';
import AboutSection from '../components/AboutSection';

export default function Homepage() {
  const isDesktopOrLaptop = useMediaQuery('(min-width:768px)');

  return (
    <div>
      <Helmet>
        <title>Bienvenue - Your Guests</title>
        <meta name="description" content="Réservez des chambres pour vos invités de mariage facilement avec Your Guests. Découvrez nos services et commencez à planifier votre mariage aujourd'hui." />
        <meta name="keywords" content="mariage, réservation, chambres, invités, your guests, planification de mariage" />
        <meta name="author" content="Your Guests" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Réservation de chambres pour mariage - Your Guests",
              "description": "Réservez des chambres pour vos invités de mariage facilement avec Your Guests.",
              "mainEntity": {
                "@type": "WebPageElement",
                "name": "Your Guests Sections",
                "hasPart": [
                  {
                    "@type": "WebPageElement",
                    "name": "Nos Services",
                    "url": "https://your-domain.com/#nos-services"
                  },
                  {
                    "@type": "WebPageElement",
                    "name": "À propos",
                    "url": "https://your-domain.com/#about"
                  }
                ]
              }
            }
          `}
        </script>
      </Helmet>
      <FullPageScroll>
        <div className="homepage">
          <IntroSection className="section" />
          <NosServicesSection className="section" />
          <AboutSection className="section" />
        </div>
      </FullPageScroll>
      {isDesktopOrLaptop && (
        <div className="fixed right-0 top-1/2">
          <Dot label="Accueil" targetId="intro" />
          <Dot label="Nos Services" targetId="nos-services" />
          <Dot label="À propos" targetId="about" />
        </div>
      )}
      <ScrollToTop />
    </div>
  )
}